<template>
  <div class="page page-daily">
    <header class="daily--header">
      <div class="daily--header--heading">
        <h1 class="daily--header--heading__title">{{ $t("daily") }}</h1>
        <h2 class="daily--header--heading__subtitle">
          {{ $t("events_and_news") }}
        </h2>
      </div>
      <div class="daily--header--actions">
        <div class="daily--header--actions--left">
          <filter-button
            class="daily--header--actions__button"
            @click="changeView('weekly')"
            :selected="
              this.$route.query.view === 'weekly' ||
              !this.$route.query.hasOwnProperty('view')
            "
          >
            <template v-slot:icon>
              <v-icon>mdi-view-list</v-icon>
            </template>
            <template v-slot:text>
              {{ $t("calendar.week") }}
            </template>
          </filter-button>
          <filter-button
            class="daily--header--actions__button"
            @click="changeView('monthly')"
            :selected="this.$route.query.view === 'monthly'"
          >
            <template v-slot:icon>
              <v-icon>mdi-view-module</v-icon>
            </template>
            <template v-slot:text>{{ $t("calendar.month") }} </template>
          </filter-button>
          <filter-button
            class="daily--header--actions__button"
            @click="goToRoute('/events/search')"
            :selected="this.$route === '/events/search'"
          >
            <template v-slot:icon> <v-icon>mdi-magnify</v-icon> </template>
            <template v-slot:text>
              {{ $t("calendar.search") }}
            </template>
          </filter-button>
        </div>
        <span class="line-divider"></span>
        <div class="daily--header--actions--right">
          <div
            class="navigation-button month-selector"
            v-if="this.$route.query.view === 'monthly'"
          >
            <button class="navigation-button__prev" @click="previousMonth">
              <v-icon>mdi-chevron-left</v-icon>
            </button>
            <span class="navigation-button__text">{{
              $t("calendar." + currentMonth.format("MMMM").toLowerCase())
            }}</span>
            <button class="navigation-button__next" @click="nextMonth">
              <v-icon>mdi-chevron-right</v-icon>
            </button>
          </div>
          <div
            class="navigation-button week-selector"
            v-if="
              !this.$route.query.view || this.$route.query.view === 'weekly'
            "
          >
            <button class="navigation-button__prev" @click="previousWeek">
              <v-icon>mdi-chevron-left</v-icon>
            </button>
            <span class="navigation-button__text"
              >{{ currentWeekStart.format("D") }}
              {{
                $t("calendar." + currentWeekStart.format("MMMM").toLowerCase())
              }}
              -
              <!-- {{ currentWeekStart.format("YYYY") }} - -->
              {{ currentWeekEnd.format("D") }}
              {{
                $t("calendar." + currentWeekEnd.format("MMMM").toLowerCase())
              }}
              <!-- {{ currentWeekEnd.format("YYYY") }} -->
            </span>
            <button class="navigation-button__next" @click="nextWeek">
              <v-icon>mdi-chevron-right</v-icon>
            </button>
          </div>
        </div>
      </div>
    </header>
    <div class="daily--content">
      <div v-if="this.$route.query.view === 'monthly'" class="events-calendar">
        <table class="events-calendar--table">
          <thead class="events-calendar--table--head">
            <tr class="events-calendar--table--head--row">
              <th class="events-calendar--table--head--row__cell">
                {{ $t("calendar.monday") }}
              </th>
              <th class="events-calendar--table--head--row__cell">
                {{ $t("calendar.tuesday") }}
              </th>
              <th class="events-calendar--table--head--row__cell">
                {{ $t("calendar.wednesday") }}
              </th>
              <th class="events-calendar--table--head--row__cell">
                {{ $t("calendar.thursday") }}
              </th>
              <th class="events-calendar--table--head--row__cell">
                {{ $t("calendar.friday") }}
              </th>
            </tr>
          </thead>

          <tbody class="events-calendar--table--body">
            <tr
              class="events-calendar--table--body--row"
              v-for="week in weeks"
              :key="week[0].format('YYYY-WW')"
            >
              <td
                class="events-calendar--table--body--row__cell"
                v-for="day in week"
                :class="{
                  'not-current-month': day.month() !== currentMonth.month(),
                  today: isToday(day),
                }"
                :key="day"
                @click="showDay(day)"
              >
                <div class="events-calendar--table--body--row__cell__day">
                  {{ day.date() }}
                </div>
                <div
                  class="events-calendar--table--body--row__cell__events"
                  v-if="!this.$vuetify.display.mobile"
                >
                  <div
                    v-for="event in filteredEvents(day)"
                    :key="event.id"
                    class="events-calendar--table--body--row__cell__events__item"
                  >
                    <month-event :event="event"></month-event>
                  </div>
                </div>
                <div
                  class="events-calendar--table--body--row__cell__events"
                  v-if="this.$vuetify.display.mobile"
                >
                  <div
                    v-for="event in filteredEvents(day)"
                    :key="event.id"
                    class="events-calendar--table--body--row__cell__events__item"
                  >
                    <div v-html="event.typology.icon"></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="events-calendar--mobile--list"
          v-if="this.$vuetify.display.mobile"
        >
          <template v-if="selectedDay">
            <div
              v-for="event in filteredEvents(selectedDay)"
              :key="event.id"
              class="events-calendar--table--body--row__cell__events__item"
            >
              <week-event-mobile :event="event"></week-event-mobile>
            </div>
          </template>
          <template v-else>
            <div
              v-for="event in events"
              :key="event.id"
              class="events-calendar--table--body--row__cell__events__item"
            >
              <week-event-mobile :event="event"></week-event-mobile>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="!this.$route.query.view || this.$route.query.view === 'weekly'"
        class="events-weekly"
      >
        <ul class="events-weekly--week">
          <li
            v-for="day in weekDays"
            :key="day.format('YYYY-MM-DD')"
            :class="{ today: isToday(day) }"
            class="events-weekly--week__day"
          >
            <div class="events-weekly--week__day__label">
              <div class="events-weekly--week__day__label__text">
                {{
                  $t("calendar." + day.format("dddd").toLowerCase() + "_full")
                }}
              </div>
              <div class="events-weekly--week__day__label__number">
                {{ day.format("DD") }}
              </div>
            </div>
            <ul
              class="events-weekly--week__day__events--mobile"
              v-if="this.$vuetify.display.mobile"
            >
              <template v-if="loading">
                <li class="events-weekly--week__day__events--mobile__no-events">
                  <div
                    class="events-weekly--week__day__events--mobile__no-events__text"
                  >
                    {{ $t("calendar.loading") }}
                  </div>
                </li>
              </template>
              <template v-if="filteredEvents(day).length > 0 && !loading">
                <li
                  v-for="event in filteredEvents(day)"
                  :key="event.id"
                  class="events-weekly--week__day__events--mobile__item"
                >
                  <week-event-mobile
                    :event="event"
                    :today="isToday(day)"
                  ></week-event-mobile>
                </li>
              </template>
              <template v-if="filteredEvents(day).length <= 0 && !loading">
                <li class="events-weekly--week__day__events--mobile__no-events">
                  <div
                    class="events-weekly--week__day__events--mobile__no-events__text"
                  >
                    {{ $t("calendar.no_events") }}
                  </div>
                </li>
              </template>
            </ul>
            <div
              class="events-weekly--week__day__events--desktop"
              v-if="!this.$vuetify.display.mobile"
            >
              <week-events-slider
                :slides="filteredEvents(day)"
                :loading="loading"
              ></week-events-slider>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import axios from "axios";
import moment from "moment";
import { useRoute } from "vue-router";

import FilterButton from "@/components/daily/FilterButton.vue";
import MonthEvent from "@/components/daily/MonthEvent.vue";
import WeekEventMobile from "@/components/daily/WeekEventMobile.vue";

import WeekEventsSlider from "@/components/sliders/WeekEventsSlider.vue";
export default {
  name: "dailyView",
  components: {
    FilterButton,
    MonthEvent,
    WeekEventMobile,
    WeekEventsSlider,
  },
  data() {
    return {
      currentDate: new Date(),
      currentMonth: moment().startOf("month"),
      currentWeekStart: moment().startOf("isoWeek"),
      currentWeekEnd: moment().endOf("isoWeek"),
      events: [],
      typologyData: null,
      loading: true,
      selectedDay: null,
    };
  },
  created() {
    document.title = this.$t("metaTitles.daily") + "| DPA UPV";
  },
  computed: {
    hasQuery() {
      const route = useRoute();
      return route.query.view !== undefined;
    },
    weeks() {
      const weeks = [];
      let currentWeek = [];
      let currentDay = this.currentMonth
        .clone()
        .startOf("month")
        .startOf("isoWeek");

      while (
        currentDay.isBefore(
          this.currentMonth.clone().endOf("month").endOf("isoWeek")
        )
      ) {
        if (currentDay.isoWeekday() !== 6 && currentDay.isoWeekday() !== 7) {
          currentWeek.push(currentDay.clone());
        }
        if (currentDay.isoWeekday() === 5) {
          weeks.push(currentWeek);
          currentWeek = [];
        }
        currentDay.add(1, "day");
      }

      if (currentWeek.length > 0) {
        weeks.push(currentWeek);
      }

      return weeks;
    },

    // weekDays() {
    //   const weekDays = [];
    //   let currentDay = this.currentWeekStart.clone();

    //   while (currentDay.isBefore(this.currentWeekEnd)) {
    //     weekDays.push(currentDay.clone());
    //     currentDay.add(1, "day");
    //   }

    //   return weekDays;
    // },
  },
  methods: {
    async loadEvents() {
      let fromDate, toDate;
      if (this.$route.query.view === "monthly") {
        fromDate = moment(this.currentMonth)
          .startOf("month")
          .format("YYYY-MM-DD");
        toDate = moment(this.currentMonth).endOf("month").format("YYYY-MM-DD");
      } else if (this.$route.query.view === "weekly") {
        fromDate = moment(this.currentWeekStart)
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        toDate = moment(this.currentWeekEnd)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      } else if (this.$route.query.view === undefined) {
        fromDate = moment(this.currentWeekStart)
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        toDate = moment(this.currentWeekEnd)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      }
      let url = `https://admin.dparq-etsav.webs.upv.es/wp-json/my/v1/events?start_date=${fromDate}&end_date=${toDate}`;

      try {
        const response = await axios.get(url);
        const events = [];
        for (const event of response.data) {
          if (event.typology.length > 0) {
            const typologyData = await PostsService.getTaxonomyFields(
              "typology",
              event.typology[0].term_id
            );
            events.push({
              id: event.id,
              slug: event.slug,
              start_date: moment(event.start_date).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              end_date: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss"),
              start_time: moment(event.start_date).format("HH:mm"),
              end_time: moment(event.end_date).format("HH:mm"),
              title: {
                es: event.title.title_es,
                en: event.title.title_en,
                ca: event.title.title_ca,
              },
              subtitle: {
                es: event.subtitle.subtitle_es,
                en: event.subtitle.subtitle_en,
                ca: event.subtitle.subtitle_ca,
              },
              image: event.cover_image
                ? event.cover_image.sizes.medium_large
                : null,
              typology: {
                name: {
                  es: typologyData.acf.translations.name_es,
                  en: typologyData.acf.translations.name_en,
                  ca: typologyData.acf.translations.name_ca,
                },
                icon: typologyData.acf.svg,
              },
              location: {
                es: event.location.location_es,
                en: event.location.location_en,
                ca: event.location.location_cat,
              },
            });
          } else {
            events.push({
              id: event.id,
              slug: event.slug,
              start_date: moment(event.start_date).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              end_date: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss"),
              start_time: moment(event.start_date).format("HH:mm"),
              end_time: moment(event.end_date).format("HH:mm"),
              title: {
                es: event.title.title_es,
                en: event.title.title_en,
                ca: event.title.title_ca,
              },
              subtitle: {
                es: event.subtitle.subtitle_es,
                en: event.subtitle.subtitle_en,
                ca: event.subtitle.subtitle_ca,
              },
              image: event.cover_image
                ? event.cover_image.sizes.medium_large
                : null,
              typology: null,
              location: {
                es: event.location.location_es,
                en: event.location.location_en,
                ca: event.location.location_cat,
              },
            });
          }
        }
        this.events.splice(0, this.events.length, ...events);
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    filteredEvents(date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");

      return this.events.filter(
        (event) =>
          moment(event.start_date).format("YYYY-MM-DD") === formattedDate
      );
    },

    async previousMonth() {
      this.currentMonth = this.currentMonth.clone().subtract(1, "month");
      this.generateMonthDays();
      this.loading = true;
      await this.loadEvents();
      this.loading = false;
    },

    async nextMonth() {
      this.currentMonth = this.currentMonth.clone().add(1, "month");
      this.generateMonthDays();
      this.loading = true;
      await this.loadEvents();
      this.loading = false;
    },

    async previousWeek() {
      this.currentWeekStart = this.currentWeekStart
        .clone()
        .subtract(1, "week")
        .startOf("isoWeek");
      this.currentWeekEnd = this.currentWeekStart
        .clone()
        .endOf("isoWeek")
        .subtract(2, "day");
      this.generateWeekDays();
      this.loading = true;
      await this.loadEvents();
      this.loading = false;
    },

    async nextWeek() {
      this.currentWeekStart = this.currentWeekStart
        .clone()
        .add(1, "week")
        .startOf("isoWeek");
      this.currentWeekEnd = this.currentWeekStart
        .clone()
        .endOf("isoWeek")
        .subtract(2, "day");
      this.generateWeekDays();
      this.loading = true;
      await this.loadEvents();
      this.loading = false;
    },
    isToday(day) {
      return day.isSame(moment(), "day");
    },
    generateMonthDays() {
      // Generamos las matrices de fechas para la vista mensual
      const monthStart = this.currentMonth.startOf("month");
      const monthEnd = this.currentMonth.endOf("month");
      const firstDayOfMonth = monthStart.day();
      const startDate = monthStart.clone().subtract(firstDayOfMonth - 1, "day");
      const endDate = monthEnd.clone().add(6 - monthEnd.day(), "day");
      const monthDays = [];
      let week = [];
      let daysInMonth = [];

      while (startDate.isBefore(endDate)) {
        week.push(startDate.clone());
        if (startDate.day() === 0) {
          monthDays.push(week);
          week = [];
        }
        startDate.add(1, "day");
      }

      if (week.length > 0) {
        monthDays.push(week);
      }

      for (let i = 0; i < monthDays.length; i++) {
        const week = monthDays[i];
        const days = [];

        for (let j = 0; j < week.length; j++) {
          const day = week[j];
          days.push({
            date: day,
            isCurrentMonth: day.isSame(this.currentMonth, "month"),
          });
        }

        daysInMonth.push(days);
      }

      this.monthDays = daysInMonth;
    },

    generateWeekDays() {
      this.weekDays = [];
      const currentDay = this.currentWeekStart.clone();

      while (currentDay.isBefore(this.currentWeekEnd)) {
        if (currentDay.isoWeekday() !== 6 && currentDay.isoWeekday() !== 7) {
          this.weekDays.push(currentDay.clone());
        }
        currentDay.add(1, "day");
      }
    },

    changeView(view) {
      this.$router.push({ query: { view: view } });
      setTimeout(async () => {
        await this.loadEvents();
      }, 100);
    },
    goToRoute(routePath) {
      this.$router.push(routePath);
    },
    showDay(day) {
      this.selectedDay = day;
    },
  },

  async mounted() {
    // Inicializamos las fechas actuales
    this.currentMonth = moment();
    this.currentWeekStart = moment().startOf("isoWeek");
    this.currentWeekEnd = moment().endOf("isoWeek");

    // Generamos las matrices de fechas para la vista mensual
    this.loading = true;
    this.generateMonthDays();

    // Generamos el array de fechas para la vista semanal
    this.generateWeekDays();
    await this.loadEvents();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

.page-daily {
  // padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    height: 100%;
  }
}

.daily--header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + #{$base * 4});
  margin-left: -$base * 2;
  padding: 0 $base * 2;
  padding-bottom: $base * 2;
  // margin-bottom: $base * 4;

  @media (min-width: 1024px) {
    padding: 0;
    width: calc(100% + #{$base * 4});
    margin-left: -$base * 2;
    margin-bottom: $base * 4;
    padding-bottom: $base * 3;
    border-bottom: solid 2px $body_color;
  }

  &--heading {
    width: calc(100% + #{$base * 4});
    // margin-left: -$base * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $base * 2;
    border-bottom: solid 2px $body_color;
    padding-bottom: $base * 3;

    @media (min-width: 1024px) {
      width: 100%;
      margin-bottom: $base * 3;
      border-bottom: none;
      padding-bottom: 0;
    }

    &__title {
      font-size: $base * 4.5;
      line-height: $base * 4;
      font-family: $font_family_alt;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: $base * 9;
        line-height: $base * 8;
      }
    }

    &__subtitle {
      font-size: $base * 2;
      line-height: $base * 2;
      font-family: $font_family_alt;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: $base * 2.5;
        line-height: $base * 2;
      }
    }
  }

  &--actions {
    display: flex;
    flex-wrap: wrap;
    gap: $base;

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      align-items: center;
    }

    &--left {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: $base;
      width: 100%;

      @media (min-width: 1024px) {
        width: auto;
        justify-content: center;
      }
    }

    .line-divider {
      display: none;
      width: 2px;
      height: 100%;
      background-color: $body_color;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    &--right {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: $base;
      width: 100%;

      @media (min-width: 1024px) {
        width: auto;
        justify-content: center;
      }

      .navigation-button {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: $base * 0.5;
        border-radius: 500px;
        border: 2px solid $body_color;
        padding: $base * 0.2 $base * 1;
        color: $body_color;
        font-size: $base * 2;
        line-height: $base * 3;
        font-family: $font_family_alt;
        text-transform: uppercase;
        font-weight: normal;
        transition: all ease 0.1s;
        width: 100%;

        button {
          &:focus {
            outline: none;
          }
        }

        @media (min-width: 1024px) {
          font-size: $base * 2.625;
          line-height: 1;
          padding: $base * 0.5 $base * 1.75;
          padding-left: $base * 1;
          width: auto;
        }

        &__text {
          padding-top: $base * 0.1;

          @media (min-width: 1024px) {
            padding-top: $base * 0.4;
          }
        }
      }
    }
  }
}

.daily--content {
  height: 100%;

  /*
** Vista Calendario
*/
  .events-calendar {
    height: 100%;
    padding: 0 $base * 0;
    padding-bottom: $base * 4;

    @media (min-width: 1024px) {
      padding: 0 $base * 2;
      padding-bottom: $base * 4;
    }

    &--table {
      width: 100%;
      height: 100%;
      max-height: var(--app-height);
      display: grid;
      grid-template-columns: repeat(5, calc(100% / 5));
      /* 7 columnas, misma anchura */
      grid-template-rows: auto repeat(5, calc(var(--app-height) * 0.5 / 5));
      /* 6 filas, misma altura */
      // grid-auto-flow: dense;
      /* Ajusta automáticamente las celdas vacías */
      border-collapse: collapse;
      @media (min-width: 1024px) {
        grid-template-columns: repeat(5, 1fr);
        /* 7 columnas, misma anchura */
        grid-template-rows: auto repeat(5, 1fr);
      }
      /* fusionar bordes */
      &--head {
        display: contents;

        &--row {
          display: contents;

          &__cell {
            text-align: center;
            /* Centra el contenido */
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: $base * 4;
            line-height: 1;
            margin-bottom: $base * 2;
            font-family: $font_family_alt;
            font-weight: 400;
          }
        }
      }

      // estilos para los bordes
      td {
        border: 1px solid $body_color;
        /* establecer borde para cada celda */
      }

      tr:first-child td,
      tr:first-child {
        border-top: none;
        /* eliminar el borde superior de la primera fila */
      }

      tr:last-child td {
        border-bottom: none;
        /* eliminar el borde inferior de la última fila */
      }

      td:first-child,
      th:first-child {
        border-left: none;
        /* eliminar el borde izquierdo de la primera columna */
      }

      td:last-child {
        border-right: none;
        /* eliminar el borde derecho de la última columna */
      }

      &--body {
        display: contents;

        &--row {
          display: contents;

          &__cell {
            text-align: center;
            /* Centra el contenido */
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: $base * 2 $base;
            font-family: $font_family_alt;

            & > div {
              font-size: $base * 3;
            }

            &.not-current-month {
              & > div {
                opacity: 0.5;
              }
            }

            &.today {
              border: solid 4px $corporate_color !important;
              background: rgba($corporate_color_rgb, 0.4) !important;
              font-weight: 700;
            }

            &__events {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: $base;
              // overflow-y: scroll;

              &__item {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &--mobile--list {
      margin-top: $base * 4;
      display: flex;
      flex-direction: column;
      gap: $base * 2;
    }
  }

  /*
** Vista Semanal
*/
  .events-weekly {
    &--week {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: $base * 4;

      &__day {
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: $base * 3;
        }

        &__label {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: flex-end;
          flex-wrap: nowrap;
          gap: $base * 1;
          border-bottom: solid 1px $body_color;
          width: calc(100% + #{$base * 2});
          margin-left: -$base * 2;
          padding: 0 $base * 4;
          padding-top: $base * 1;

          @media (min-width: 1024px) {
            grid-column: 1 / span 2;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: $base * 1;
            margin-left: 0;
            width: 100%;
            background-color: #efefef;
            padding: $base * 2;
          }

          &__text {
            font-size: $base * 2;
            line-height: $base * 3;
            font-family: $font_family_alt;
            text-transform: uppercase;
            font-weight: 400;

            @media (min-width: 1024px) {
              font-size: $base * 2.625;
              line-height: $base * 3;
              flex-direction: column;
              flex-wrap: wrap;
            }
          }

          &__number {
            font-size: $base * 4.5;
            line-height: $base * 4;
            font-family: $font_family_alt;
            text-transform: uppercase;
            font-weight: 400;

            @media (min-width: 1024px) {
              font-size: $base * 11;
              line-height: $base * 10;
              flex-direction: column;
              flex-wrap: wrap;
            }
          }
        }

        &__events--mobile {
          width: 100%;
          display: flex;
          flex-direction: column;
          list-style: none;
          margin: 0;
          padding: $base * 2;
          gap: $base * 2;

          &__no-events {
            width: 100%;
            min-height: $base * 8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__text {
              font-size: $base * 1.75;
              line-height: $base * 3;
              text-align: center;
              color: #a5a5a5;
            }
          }
        }

        &__events--desktop {
          grid-column: 3 / span 10;
          width: 100%;
          display: flex;
          background-color: #efefef;
        }

        &.today {
          .events-weekly--week__day__label {
            border-bottom: solid 3px $body_color !important;
            background: rgba($corporate_color_rgb, 0.4);

            @media (min-width: 1024px) {
              border-bottom: solid 10px $corporate_color !important;
              background: rgba($corporate_color_rgb, 0.5);
            }
          }

          .events-weekly--week__day__events--desktop {
            background: rgba($corporate_color_rgb, 0.5);
          }

          .events-weekly--week__day__events--mobile {
            &__no-events {
              &__text {
                color: rgba($corporate_color_rgb, 0.6);
              }
            }
          }
        }
      }
    }
  }
}
</style>
