<template>
  <div class="page page--search page--daily">
    <header class="daily--header">
      <div class="daily--header--heading">
        <h1 class="daily--header--heading__title">{{ $t("daily") }}</h1>
        <h2 class="daily--header--heading__subtitle">
          {{ $t("events_and_news") }}
        </h2>
      </div>
      <div class="daily--header--actions">
        <div class="daily--header--actions--left">
          <filter-button
            class="daily--header--actions__button"
            @click="goToRoute('/events?view=weekly')"
          >
            <template v-slot:icon>
              <v-icon>mdi-view-list</v-icon>
            </template>
            <template v-slot:text>
              {{ $t("calendar.week") }}
            </template>
          </filter-button>
          <filter-button
            class="daily--header--actions__button"
            @click="goToRoute('/events?view=monthly')"
          >
            <template v-slot:icon>
              <v-icon>mdi-view-module</v-icon>
            </template>
            <template v-slot:text>{{ $t("calendar.month") }} </template>
          </filter-button>
          <filter-button
            class="daily--header--actions__button"
            @click="goToRoute('/events/search')"
            selected
          >
            <template v-slot:icon> <v-icon>mdi-magnify</v-icon> </template>
            <template v-slot:text>
              {{ $t("calendar.search") }}
            </template>
          </filter-button>
          <filter-button
            class="daily--header--actions__button"
            @click="toggleFilters"
            :selected="showFilters === true"
            v-if="this.$vuetify.display.mobile"
          >
            <template v-slot:icon>
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1048_3913)">
                  <path
                    d="M12.9999 1.625H16.2142M0.785645 1.625H10.4285M12.9999 11.375H16.2142M0.785645 11.375H10.4285M6.57136 6.5H16.2142M0.785645 6.5H3.99993"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.7142 2.84375C12.4243 2.84375 12.9999 2.2981 12.9999 1.625C12.9999 0.951903 12.4243 0.40625 11.7142 0.40625C11.0041 0.40625 10.4285 0.951903 10.4285 1.625C10.4285 2.2981 11.0041 2.84375 11.7142 2.84375Z"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.28571 7.71875C5.99579 7.71875 6.57143 7.1731 6.57143 6.5C6.57143 5.8269 5.99579 5.28125 5.28571 5.28125C4.57563 5.28125 4 5.8269 4 6.5C4 7.1731 4.57563 7.71875 5.28571 7.71875Z"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.7142 12.5938C12.4243 12.5938 12.9999 12.0481 12.9999 11.375C12.9999 10.7019 12.4243 10.1562 11.7142 10.1562C11.0041 10.1562 10.4285 10.7019 10.4285 11.375C10.4285 12.0481 11.0041 12.5938 11.7142 12.5938Z"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1048_3913">
                    <rect
                      width="16"
                      height="13"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </template>
          </filter-button>
        </div>
        <span class="line-divider" v-if="!this.$vuetify.display.mobile"></span>
        <div class="daily--header--actions--right">
          <input
            v-model="searchTerm"
            :placeholder="$t('calendar.searchByWord')"
            class="custom-input"
          />
          <div class="daily--header--actions--right__selectors">
            <CustomSelect
              v-model="year"
              :options="yearOptions"
              @input="searchYearEvents"
              :placeholder="$t('calendar.year')"
              :noneOptionLabel="$t('calendar.year')"
              v-if="showFilters || !this.$vuetify.display.mobile"
            />
            <CustomSelect
              v-model="month"
              :options="monthOptions"
              @input="searchMonthEvents"
              :placeholder="$t('calendar.month')"
              :noneOptionLabel="$t('calendar.month')"
              v-if="showFilters || !this.$vuetify.display.mobile"
            />
            <CustomSelect
              v-model="typology"
              :options="typologyOptions"
              @input="searchTypologyEvents"
              :placeholder="$t('calendar.typology')"
              :noneOptionLabel="$t('calendar.typology')"
              v-if="showFilters || !this.$vuetify.display.mobile"
            />
          </div>
        </div>
      </div>
    </header>
    <div class="daily--content" :class="showFilters ? 'has-filters' : ''">
      <div class="daily--content__message" v-if="loading">
        {{ $t("calendar.loading") }}
      </div>
      <div class="daily--content__message" v-if="!loading && !events.length">
        {{ $t("calendar.no_events") }}
      </div>
      <ul class="daily--content--list" v-if="!loading && events.length > 0">
        <li v-for="event in events" :key="event.id">
          <search-event-card :event="event" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FilterButton from "@/components/daily/FilterButton.vue";
import CustomSelect from "@/components/daily/CustomSelect.vue";
import SearchEventCard from "@/components/daily/SearchEventCard.vue";

import PostsService from "@/services/posts.service.js";
import moment from "moment";
import sanitizeHtml from "sanitize-html";

import axios from "axios";

export default {
  components: {
    CustomSelect,
    FilterButton,
    SearchEventCard,
  },
  data() {
    return {
      searchTerm: "",
      year: "",
      month: "",
      typology: "",
      events: [],
      loading: false,
      yearOptions: [],
      monthOptions: [],
      typologyOptions: [],
      activeSelect: null,
      showFilters: false,
    };
  },
  created() {
    this.getEvents();
    this.getYearOptions();
    this.getMonthOptions();
    this.getTypologyOptions();
    document.title = this.$t("metaTitles.daily") + "| DPA UPV";
  },
  methods: {
    toggleActiveSelect(index) {
      if (this.activeSelect === index) {
        this.activeSelect = null;
      } else {
        this.activeSelect = index;
      }
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    async getEvents() {
      this.loading = true;
      let params = {
        lang: this.$root.$i18n.locale,
      };
      if (this.searchTerm) {
        params.search = this.searchTerm;
      }
      if (this.year) {
        params.year = this.year;
      }
      if (this.month) {
        params.month = this.month;
      }
      if (this.typology) {
        params.typology = parseInt(this.typology);
      }
      try {
        const response = await axios.get(
          "https://admin.dparq-etsav.webs.upv.es/wp-json/mytheme/v1/events",
          {
            params: params,
          }
        );

        const eventData = await Promise.all(
          response.data.map(async (event) => {
            const excerptEs = sanitizeHtml(
              event.event_content.content.content_es,
              {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              }
            );
            const excerptEn = sanitizeHtml(
              event.event_content.content.content_en,
              {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              }
            );
            const excerptCa = sanitizeHtml(
              event.event_content.content.content_cat,
              {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              }
            );
            const excerptLimit = this.$vuetify.display.mobile ? 1 : 0.2;
            const res = {
              id: event.id,
              slug: event.slug,
              title: event.title,
              date: moment(event.start_date).format("DD/MM/YYYY"),
              typology: event.typology,
              cover_image: {
                medium: event.cover_image.sizes.medium,
                large: event.cover_image.sizes.large,
                full: event.cover_image.url,
              },
              content: {
                es:
                  excerptEs.length > this.$vuetify.display.height * excerptLimit
                    ? excerptEs.substring(
                        0,
                        this.$vuetify.display.height * excerptLimit
                      ) + "..."
                    : excerptEs,
                en:
                  excerptEn.length > this.$vuetify.display.height * excerptLimit
                    ? excerptEn.substring(
                        0,
                        this.$vuetify.display.height * excerptLimit
                      ) + "..."
                    : excerptEn,
                ca:
                  excerptCa.length > this.$vuetify.display.height * excerptLimit
                    ? excerptCa.substring(
                        0,
                        this.$vuetify.display.height * excerptLimit
                      ) + "..."
                    : excerptCa,
              },
              location: {
                es: event.location.location_es,
                ca: event.location.location_cat,
                en: event.location.location_en,
              },
            };

            const typologyId = event.typology.term_id;
            const typologyData = await PostsService.getTaxonomyFields(
              "typology",
              typologyId
            );

            res.typology = {
              ca: typologyData.acf.translations.name_ca,
              es: typologyData.acf.translations.name_es,
              en: typologyData.acf.translations.name_en,
              icon: typologyData.acf.svg,
            };

            return res;
          })
        );

        this.events = eventData;
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        this.loading = false;
      }
    },
    getYearOptions() {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        this.yearOptions.push({ value: i, label: i });
      }
    },
    getMonthOptions() {
      for (let i = 1; i <= 12; i++) {
        this.monthOptions.push({ value: i, label: i });
      }
    },
    async getTypologyOptions() {
      try {
        const response = await axios.get(
          "https://admin.dparq-etsav.webs.upv.es/wp-json/wp/v2/typology"
        );
        const rawTypologies = response.data;
        this.typologyOptions = rawTypologies.map((typology) => {
          return {
            value: typology.id,
            label: typology.acf.translations[`name_${this.$i18n.locale}`],
          };
        });
        // console.log("typologyOptions", this.typologyOptions);
      } catch (error) {
        console.error("Error fetching typologies:", error);
        return [];
      }
    },

    searchYearEvents(param) {
      this.year = param;
      this.getEvents();
    },
    searchMonthEvents(param) {
      this.month = param;
      this.getEvents();
    },
    searchTypologyEvents(param) {
      this.typology = param;
      this.getEvents();
    },
    goToRoute(routePath) {
      this.$router.push(routePath);
    },
  },
  watch: {
    searchTerm: {
      handler: function () {
        this.getEvents();
      },
    },
    // year: {
    //   handler: function () {
    //     console.log("year changed:", this.year);
    //     this.searchYearEvents();
    //   },
    // },
    // month: {
    //   handler: function () {
    //     console.log("month changed:", this.month);
    //     this.searchMonthEvents();
    //   },
    // },
    // typology: {
    //   handler: function () {
    //     console.log("typology changed:", this.typology);
    //     this.searchTypologyEvents();
    //   },
    // },
  },
};
</script>
<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }
.page--daily {
  // padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    height: 100%;
  }
}

.daily--header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + #{$base * 4});
  margin-left: -$base * 2;
  padding: 0 $base * 2;
  padding-bottom: $base * 2; //
  margin-bottom: $base * 4;
  position: relative;

  @media (min-width: 1024px) {
    padding: 0;
    width: calc(100% + #{$base * 4});
    margin-left: -$base * 2;
    margin-bottom: $base * 4;
    padding-bottom: $base * 3;
    border-bottom: solid 2px $body_color;
  }

  &--heading {
    width: calc(100% + #{$base * 4}); // margin-left: -$base * 2; display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $base * 2;
    border-bottom: solid 2px $body_color;
    padding-bottom: $base * 3;

    @media (min-width: 1024px) {
      width: 100%;
      margin-bottom: $base * 3;
      border-bottom: none;
      padding-bottom: 0;
    }

    &__title {
      font-size: $base * 4.5;
      line-height: $base * 4;
      font-family: $font_family_alt;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: $base * 9;
        line-height: $base * 8;
      }
    }

    &__subtitle {
      font-size: $base * 2;
      line-height: $base * 2;
      font-family: $font_family_alt;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: $base * 2.5;
        line-height: $base * 2;
      }
    }
  }

  &--actions {
    display: flex;
    flex-wrap: wrap;
    gap: $base;
    position: absolute;
    top: calc(100% - #{$base * 2});

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      align-items: center;
      position: static;
    }

    &--left {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: $base;
      width: 100%;

      @media (min-width: 1024px) {
        width: auto;
        justify-content: center;
      }
    }

    .line-divider {
      display: none;
      width: 2px;
      height: 100%;
      background-color: $body_color;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    &--right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: $base;
      width: 100%;
      &__selectors {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: $base;
        width: 100%;
      }

      @media (min-width: 1024px) {
        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        max-width: 100%;
      }
    }
  }
}

.daily--content {
  padding-top: $base * 5;
  width: calc(100% + #{$base * 2});
  margin-left: -$base;
  padding-bottom: $base * 4;
  min-height: 50vh;
  &.has-filters {
    padding-top: $base * 10;
  }
  @media (min-width: 1024px) {
    width: 100%;
    margin-left: 0;
    padding-top: $base * 0;
    padding-bottom: $base * 3;
    min-height: auto;
  }
  &__message {
    min-height: $base * 30;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $base * 2;
    font-size: $base * 3;
    font-family: $font_family_alt;
    text-transform: uppercase;
    background: rgba($body_color_rgb, 0.1);
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: $base * 3;
  }
}

.custom-input {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: $base * 0.5;
  border-radius: 500px;
  border: 2px solid $body_color;
  background-color: $body_bg;
  padding: $base * 0.2 $base * 1;
  // padding-bottom: $base * 0;
  height: $base * 4;
  color: $body_color;
  font-size: $base * 2;
  line-height: $base * 3;
  transition: all ease 0.1s;
  width: 100%;
  min-width: 100%;

  &:focus {
    //change outline color to $corporate_color;
    outline: none;
    border: 2px solid $corporate_color;
  }

  @media (min-width: 1024px) {
    min-width: $base * 30;
    height: $base * 5;
    font-size: $base * 2.625;
    line-height: $base * 5;
    padding: $base * 0.5 $base * 1.75;
  }
}
</style>
