<template>
  <div
    class="custom-select"
    :class="isOpen ? 'open' : ''"
    @click="toggleSelect"
    ref="container"
  >
    <div class="selected-option" :class="selectedOption ? 'selected' : ''">
      {{ selectedOption ? selectedOption.label : placeholder }}
      <v-icon class="chevron">mdi-chevron-down</v-icon>
    </div>
    <div class="options" v-show="isOpen" ref="optionsContainer">
      <div
        class="option"
        v-for="(option, index) in optionsWithNone"
        :key="option.value"
        @click.stop="
          option.value === null
            ? resetSelectedOption(option)
            : selectOption(option)
        "
      >
        {{ index === 0 ? noneOptionLabel : option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: { type: Array, required: true },
    value: { type: String, default: "" },
    placeholder: { type: String, default: "Select" },
    noneOptionLabel: { type: String, default: "None" },
  },
  data() {
    return {
      selectedOption: null,
      isOpen: false,
    };
  },
  computed: {
    optionsWithNone() {
      // Agregar opción "ninguna selección" al inicio del array de opciones
      return [{ label: this.noneOptionLabel, value: null }, ...this.options];
    },
  },
  created() {
    this.selectedOption = this.options.find(
      (option) => option.value === this.value
    );
  },
  mounted() {
    // this.$nextTick(() => {});
    setTimeout(() => {
      let optionsLength = this.options.length;
      //guarda optionsLength como css variable dentro de este coponente
      if (this.$refs.container) {
        this.$refs.container.style.setProperty(
          "--optionsLength",
          optionsLength
        );
      }
    }, 1000);
  },
  methods: {
    toggleSelect() {
      let optionsLength = this.options.length;
      //guarda optionsLength como css variable dentro de este coponente
      this.$refs.container.style.setProperty("--optionsLength", optionsLength);
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      // console.log("option.value:", option.value);
      this.$emit("input", option.value); // Emitir evento para actualizar el valor del select original
      this.$emit("change"); // Emitir evento change para actualizar la lista de eventos
    },
    resetSelectedOption(option) {
      this.selectedOption = null;
      this.isOpen = false;
      this.$emit("input", option.value); // Emitir evento para actualizar el valor del select original
      this.$emit("change"); // Emitir evento change para actualizar la lista de eventos
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-radius: $base * 3;
  // border: 2px solid $body_color;
  background: transparent;
  color: $body_color;
  font-size: $base * 2;
  line-height: $base * 3;
  font-family: $font_family_alt;
  text-transform: uppercase;
  font-weight: normal;
  transition: all ease 0.1s;
  width: 100%;
  height: $base * 4;
  z-index: 50;

  @media (min-width: 1024px) {
    font-size: $base * 2.625;
    line-height: 1;
    height: $base * 5;
  }

  .selected-option {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: $body_bg;
    z-index: 1;
    border-radius: $base * 3;
    border: 2px solid $body_color;
    cursor: pointer;
    padding: $base * 0.2 $base * 1;
    padding-top: $base * 0.5;
    padding-right: $base * 1;
    white-space: nowrap;
    height: $base * 4;
    line-height: $base * 4;

    &.selected {
      background: rgba($corporate_color_rgb, 0.7);
    }

    & .chevron {
      padding-bottom: $base * 0.5;
    }

    @media (min-width: 1024px) {
      padding: $base * 0.5 $base * 1.75;
      padding-bottom: $base * 0;
      padding-top: $base * 0.5;
      padding-right: $base * 1;
      height: $base * 5;
      line-height: $base * 5;
    }
  }

  .options {
    margin-top: -$base * 1.5;
    background-color: $body_bg;
    border-radius: 0 0 $base * 3 $base * 3;
    border: 2px solid $body_color;
    border-top: none;
    z-index: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    margin-left: -0px;
    padding-top: $base * 2;
    height: 100%;
    min-height: 0;

    @media (min-width: 1024px) {
      margin-top: -$base * 3;
      padding-top: $base * 4;
    }
    .option {
      padding: 0.5rem;
      height: $base * 4;
      cursor: pointer;
      white-space: nowrap;
      line-height: $base * 6;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:first-of-type {
        opacity: 0.6;
      }
      &:hover {
        background-color: #ccc;
      }
    }
  }

  &.open .options {
    min-height: calc((var(--optionsLength) + 1) * $base * 4 + $base * 4);
    transition: all ease 0.1s;
    @media (min-width: 1024px) {
      min-height: calc((var(--optionsLength) + 1) * $base * 4 + $base * 5.5);
    }
  }

  &.open .selected-option .chevron {
    transform: rotate(180deg);
    padding-bottom: 0;
    padding-top: $base * 0.5;
  }
  &.open .selected-option {
  }
}
</style>
