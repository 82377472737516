<template>
  <div class="page-content page-events-single" v-if="parsedPost">
    <breadcrumbs-component :breadcrumbs="breadcrumbs"></breadcrumbs-component>
    <div class="event-single--pre">
      <span
        class="event-single--pre__text"
        v-if="this.$root.$i18n.locale == 'es'"
        >{{ parsedPost.typology.es }}</span
      >
      <span
        class="event-single--pre__text"
        v-if="this.$root.$i18n.locale == 'en'"
        >{{
          parsedPost.typology.en
            ? parsedPost.typology.en
            : parsedPost.typology.es
        }}</span
      >
      <span
        class="event-single--pre__text"
        v-if="this.$root.$i18n.locale == 'ca'"
        >{{
          parsedPost.typology.ca
            ? parsedPost.typology.ca
            : parsedPost.typology.es
        }}</span
      >
      <span class="event-single--pre__icon" v-html="parsedPost.typology.icon">
      </span>
    </div>
    <header class="events-single--header">
      <div class="events-single--header--left">
        <h1
          class="events-single--header--left__title"
          v-if="this.$root.$i18n.locale == 'es'"
        >
          {{ parsedPost.title.es }}
        </h1>
        <h1
          class="events-single--header--left__title"
          v-if="this.$root.$i18n.locale == 'en'"
        >
          {{ parsedPost.title.en ? parsedPost.title.en : parsedPost.title.es }}
        </h1>
        <h1
          class="events-single--header--left__title"
          v-if="this.$root.$i18n.locale == 'ca'"
        >
          {{ parsedPost.title.ca ? parsedPost.title.ca : parsedPost.title.es }}
        </h1>
        <h2
          class="events-single--header--left__subtitle"
          v-if="this.$root.$i18n.locale == 'es'"
        >
          {{ parsedPost.subtitle.es }}
        </h2>
        <h2
          class="events-single--header--left__subtitle"
          v-if="this.$root.$i18n.locale == 'en'"
        >
          {{
            parsedPost.subtitle.en
              ? parsedPost.subtitle.en
              : parsedPost.subtitle.es
          }}
        </h2>
        <h2
          class="events-single--header--left__subtitle"
          v-if="this.$root.$i18n.locale == 'ca'"
        >
          {{
            parsedPost.subtitle.ca
              ? parsedPost.subtitle.ca
              : parsedPost.subtitle.es
          }}
        </h2>
        <div
          class="events-single--header--left__date"
          v-if="this.$vuetify.display.mobile"
        >
          {{ parsedPost.startDate.format("DD.MM.YY - H:mm") }}
        </div>
      </div>
      <div
        class="events-single--header--right"
        v-if="!this.$vuetify.display.mobile"
      >
        <div class="events-single--header--right__external-link">
          <a
            :href="parsedPost.externalLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.67955 6.76644L6.76622 9.67978C6.69796 9.74748 6.64377 9.82804 6.6068 9.91679C6.56982 10.0055 6.55078 10.1007 6.55078 10.1969C6.55078 10.293 6.56982 10.3882 6.6068 10.477C6.64377 10.5657 6.69796 10.6463 6.76622 10.714C6.83393 10.7823 6.91448 10.8365 7.00324 10.8734C7.09199 10.9104 7.18719 10.9294 7.28334 10.9294C7.37949 10.9294 7.47468 10.9104 7.56344 10.8734C7.65219 10.8365 7.73275 10.7823 7.80045 10.714L10.7138 7.80068C10.8509 7.66353 10.928 7.47752 10.928 7.28356C10.928 7.0896 10.8509 6.90359 10.7138 6.76644C10.5766 6.62929 10.3906 6.55225 10.1967 6.55225C10.0027 6.55225 9.8167 6.62929 9.67955 6.76644Z"
                  fill="black"
                />
                <path
                  d="M8.94218 12.6732L8.00991 13.5982C7.48655 14.1375 6.78299 14.4652 6.03343 14.519C5.28386 14.5727 4.54075 14.3486 3.94581 13.8895C3.63137 13.6304 3.37478 13.3082 3.1926 12.9438C3.01043 12.5793 2.90672 12.1807 2.88819 11.7737C2.86966 11.3666 2.93671 10.9603 3.08501 10.5808C3.23331 10.2013 3.45957 9.8571 3.74916 9.57049L4.7834 8.52897C4.85166 8.46126 4.90585 8.38071 4.94282 8.29195C4.9798 8.2032 4.99884 8.108 4.99884 8.01185C4.99884 7.9157 4.9798 7.82051 4.94282 7.73175C4.90585 7.643 4.85166 7.56244 4.7834 7.49474C4.71569 7.42647 4.63514 7.37229 4.54638 7.33531C4.45763 7.29833 4.36243 7.2793 4.26628 7.2793C4.17013 7.2793 4.07494 7.29833 3.98618 7.33531C3.89743 7.37229 3.81687 7.42647 3.74916 7.49474L2.82418 8.427C2.04498 9.18156 1.56605 10.1932 1.4763 11.2742C1.38656 12.3551 1.6921 13.4319 2.3362 14.3047C2.71848 14.8006 3.20192 15.2095 3.7544 15.5043C4.30688 15.799 4.91574 15.9728 5.54056 16.0141C6.16537 16.0555 6.79183 15.9634 7.37832 15.744C7.9648 15.5246 8.4979 15.1829 8.94218 14.7417L9.97641 13.7074C10.1136 13.5703 10.1906 13.3843 10.1906 13.1903C10.1906 12.9963 10.1136 12.8103 9.97641 12.6732C9.83927 12.536 9.65325 12.459 9.4593 12.459C9.26534 12.459 9.07933 12.536 8.94218 12.6732Z"
                  fill="black"
                />
                <path
                  d="M14.3163 2.3454C13.4375 1.6946 12.3519 1.38595 11.2623 1.4771C10.1726 1.56824 9.15334 2.05294 8.39492 2.84066L7.60832 3.64183C7.51842 3.70719 7.44324 3.7907 7.38765 3.88695C7.33206 3.98321 7.29731 4.09006 7.28564 4.2006C7.27397 4.31114 7.28564 4.4229 7.31989 4.52864C7.35415 4.63438 7.41023 4.73175 7.48451 4.81445C7.55221 4.88271 7.63277 4.9369 7.72152 4.97387C7.81028 5.01085 7.90547 5.02989 8.00162 5.02989C8.09777 5.02989 8.19297 5.01085 8.28172 4.97387C8.37048 4.9369 8.45103 4.88271 8.51874 4.81445L9.46557 3.86033C9.98604 3.31868 10.6888 2.98912 11.438 2.93532C12.1873 2.88151 12.9299 3.10727 13.5224 3.569C13.8392 3.82814 14.0978 4.15112 14.2814 4.5169C14.4651 4.88267 14.5695 5.28303 14.5881 5.69189C14.6066 6.10075 14.5388 6.50893 14.3891 6.88983C14.2394 7.27073 14.0111 7.61581 13.719 7.90258L12.6848 8.9441C12.6165 9.0118 12.5624 9.09236 12.5254 9.18111C12.4884 9.26987 12.4694 9.36506 12.4694 9.46121C12.4694 9.55736 12.4884 9.65256 12.5254 9.74131C12.5624 9.83007 12.6165 9.91062 12.6848 9.97833C12.7525 10.0466 12.8331 10.1008 12.9218 10.1378C13.0106 10.1747 13.1058 10.1938 13.2019 10.1938C13.2981 10.1938 13.3933 10.1747 13.482 10.1378C13.5708 10.1008 13.6513 10.0466 13.719 9.97833L14.7533 8.9441C15.1933 8.49989 15.5339 7.96726 15.7526 7.38149C15.9712 6.79571 16.063 6.17017 16.0216 5.54628C15.9803 4.92239 15.8069 4.3144 15.5129 3.76258C15.2189 3.21076 14.811 2.7277 14.3163 2.3454Z"
                  fill="black"
                />
              </svg>
            </span>
            {{ $t("go_to_external_link") }}
          </a>
        </div>
        <div
          class="events-single--header--right__location"
          v-if="this.$root.$i18n.locale == 'es'"
        >
          {{ parsedPost.location.es }}
        </div>
        <div
          class="events-single--header--right__location"
          v-if="this.$root.$i18n.locale == 'en'"
        >
          {{
            parsedPost.location.en
              ? parsedPost.location.en
              : parsedPost.location.es
          }}
        </div>
        <div
          class="events-single--header--right__location"
          v-if="this.$root.$i18n.locale == 'ca'"
        >
          {{
            parsedPost.location.ca
              ? parsedPost.location.ca
              : parsedPost.location.es
          }}
        </div>
        <div class="events-single--header--right__date">
          {{ parsedPost.startDate.format("DD.MM.YYYY") }}
        </div>
        <div class="events-single--header--right__time">
          {{ $t("at") + " " + parsedPost.startDate.format("HH:mm") }}
        </div>
      </div>
    </header>
    <div class="events-single--content">
      <div
        class="events-single--content__gallery"
        v-if="this.layout === 'gallery'"
      >
        <event-gallery-slider
          :slides="parsedPost.eventContent.imageGallery"
        ></event-gallery-slider>
      </div>
      <div
        class="events-single--content__left"
        :class="{ 'no-image-layout': layout != 'image' }"
      >
        <div
          class="events-single--content__left__text"
          v-if="this.$root.$i18n.locale == 'es'"
          v-html="parsedPost.eventContent.content.es"
        ></div>
        <div
          class="events-single--content__left__text"
          v-if="this.$root.$i18n.locale == 'en'"
          v-html="
            parsedPost.eventContent.content.en
              ? parsedPost.eventContent.content.en
              : parsedPost.eventContent.content.es
          "
        ></div>
        <div
          class="events-single--content__left__text"
          v-if="this.$root.$i18n.locale == 'ca'"
          v-html="
            parsedPost.eventContent.content.ca
              ? parsedPost.eventContent.content.ca
              : parsedPost.eventContent.content.es
          "
        ></div>
      </div>
      <div class="events-single--content__right" v-if="this.layout === 'image'">
        <div class="events-single--content__right__image">
          <div class="events-single--content__right__image__img">
            <img
              :src="parsedPost.eventContent.image.src.sizes.large"
              :alt="parsedPost.eventContent.image.src.alt"
            />
          </div>
          <div
            class="events-single--content__right__image__caption"
            v-if="this.$root.$i18n.locale == 'es'"
          >
            {{ parsedPost.eventContent.image.caption.es }}
          </div>
          <div
            class="events-single--content__right__image__caption"
            v-if="this.$root.$i18n.locale == 'en'"
          >
            {{
              parsedPost.eventContent.image.caption.en
                ? parsedPost.eventContent.image.caption.en
                : parsedPost.eventContent.image.caption.es
            }}
          </div>
          <div
            class="events-single--content__right__image__caption"
            v-if="this.$root.$i18n.locale == 'ca'"
          >
            {{
              parsedPost.eventContent.image.caption.ca
                ? parsedPost.eventContent.image.caption.ca
                : parsedPost.eventContent.image.caption.es
            }}
          </div>
        </div>
      </div>
      <div class="events-single--content__bottom">
        <h3 class="events-single--content__bottom__title">
          {{ $t("related_events") }}
        </h3>
        <related-events-slider
          :slides="relatedPosts"
          v-if="this.relatedPosts.length > 0"
        ></related-events-slider>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import BreadcrumbsComponent from "@/components/layout/BreadcrumbsComponent.vue";
import EventGallerySlider from "@/components/sliders/EventGallerySlider.vue";
import RelatedEventsSlider from "@/components/sliders/RelatedEventsSlider.vue";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    BreadcrumbsComponent,
    EventGallerySlider,
    RelatedEventsSlider,
  },
  data() {
    return {
      parsedPost: null,
      isLoading: true,
      layout: null,
      relatedPostsSize: 6,
      relatedPosts: [],
    };
  },

  async created() {
    await this.fetchPost();
    this.layout = this.parsedPost.eventContent.layout;
    this.fetchRelatedPosts();
    // if (this.parsedPost) {
    //   if (this.$root.$i18n.locale == "es") {
    //     document.title =
    //       this.$("metaTitles.events") + this.parsedPost.title.es + "| DPA UPV";
    //   } else if (this.$root.$i18n.locale == "en") {
    //     document.title =
    //       this.$("metaTitles.events") + this.parsedPost.title.en + "| DPA UPV";
    //   } else if (this.$root.$i18n.locale == "ca") {
    //     document.title =
    //       this.$("metaTitles.events") + this.parsedPost.title.ca + "| DPA UPV";
    //   }
    // }
  },
  mounted() {},
  computed: {
    breadcrumbs() {
      return [
        {
          text: {
            es: this.$t("daily"),
            ca: this.$t("daily"),
            en: this.$t("daily"),
          },
          to: "/daily",
        },
        {
          text: {
            es: this.parsedPost.typology.es
              ? this.parsedPost.typology.es
              : this.parsedPost.typology.es,
            ca: this.parsedPost.typology.ca
              ? this.parsedPost.typology.ca
              : this.parsedPost.typology.es,
            en: this.parsedPost.typology.en
              ? this.parsedPost.typology.en
              : this.parsedPost.typology.es,
          },
          to: "",
        },
        {
          text: {
            es: this.parsedPost.title.es
              ? this.parsedPost.title.es
              : this.parsedPost.title.es,
            ca: this.parsedPost.title.ca
              ? this.parsedPost.title.ca
              : this.parsedPost.title.es,
            en: this.parsedPost.title.en
              ? this.parsedPost.title.en
              : this.parsedPost.title.es,
          },
          to: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    async fetchPost() {
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API
      this.loading = true;

      try {
        this.parsedPost = await PostsService.getSingleEvent(
          this.$route.params.id
        );

        // Procesa la respuesta de la API y actualiza las variables existentes
        this.parsedPost.title = {
          es: this.parsedPost.acf.title.title_es,
          en: this.parsedPost.acf.title.title_en,
          ca: this.parsedPost.acf.title.title_ca,
        };
        this.parsedPost.subtitle = {
          es: this.parsedPost.acf.subtitle.subtitle_es,
          en: this.parsedPost.acf.subtitle.subtitle_en,
          ca: this.parsedPost.acf.subtitle.subtitle_cat,
        };
        this.parsedPost.typology = this.parsedPost.acf.typology.term_id;
        this.parsedPost.startDate = moment(this.parsedPost.start_date);
        this.parsedPost.location = {
          es: this.parsedPost.acf.location.location_es,
          en: this.parsedPost.acf.location.location_en,
          ca: this.parsedPost.acf.location.location_cat,
        };
        this.parsedPost.coverImage = {
          medium: this.parsedPost.acf.cover_image.sizes.medium,
          large: this.parsedPost.acf.cover_image.sizes.large,
          full: this.parsedPost.acf.cover_image.url,
        };
        this.parsedPost.eventContent = {
          layout: this.parsedPost.acf.event_content.layout,
          imageGallery: this.parsedPost.acf.event_content.image_gallery,
          image: {
            src: this.parsedPost.acf.event_content.image.image,
            caption: {
              es: this.parsedPost.acf.event_content.image.caption.caption_es,
              en: this.parsedPost.acf.event_content.image.caption.caption_en,
              ca: this.parsedPost.acf.event_content.image.caption.caption_cat,
            },
          },
          content: {
            es: this.parsedPost.acf.event_content.content.content_es,
            en: this.parsedPost.acf.event_content.content.content_en,
            ca: this.parsedPost.acf.event_content.content.content_cat,
          },
        };

        // Get typology data
        const typologyId = this.parsedPost.acf.typology.term_id;
        const typologyData = await PostsService.getTaxonomyFields(
          "typology",
          typologyId
        );

        this.parsedPost.typology = {
          id: typologyId,
          ca: typologyData.acf.translations.name_ca,
          es: typologyData.acf.translations.name_es,
          en: typologyData.acf.translations.name_en,
          icon: typologyData.acf.svg,
        };
      } catch (error) {
        // Maneja el error de la llamada a la API
        console.error(error);
        this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
      } finally {
        this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
        this.isLoading = false;
      }
    },

    async fetchRelatedPosts() {
      try {
        const relatedPosts = await PostsService.getRelatedEvents(
          this.parsedPost.typology.id,
          this.relatedPostsSize
        );
        const customRelatedPosts = relatedPosts.map((post) => {
          // Aquí puedes personalizar el objeto de cada post como desees
          return {
            id: post.id,
            slug: post.slug,
            title: {
              es: post.acf.title.title_es,
              en: post.acf.title.title_en,
              ca: post.acf.title.title_ca,
            },
            typology: post.typology,
            image: {
              medium: post.acf.cover_image
                ? post.acf.cover_image.sizes.medium
                : null,
              large: post.acf.cover_image
                ? post.acf.cover_image.sizes.large
                : null,
              full: post.acf.cover_image ? post.acf.cover_image.url : null,
            },
            // Agrega más propiedades si lo deseas
          };
        });

        // Get typology data
        const typologyId = this.parsedPost.typology.id;
        const typologyData = await PostsService.getTaxonomyFields(
          "typology",
          typologyId
        );

        customRelatedPosts.forEach((post) => {
          post.typology = {
            ca: typologyData.acf.translations.name_ca,
            es: typologyData.acf.translations.name_es,
            en: typologyData.acf.translations.name_en,
            icon: typologyData.acf.svg,
          };
        });

        this.relatedPosts = customRelatedPosts;
        // console.log(this.relatedPosts);
      } catch (error) {
        console.error(error);
        this.relatedPosts = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-events-single {
  padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;
  margin-bottom: $base * 4;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    // grid-auto-rows: max-content;
    column-gap: $base * 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.breadcrumbs-nav {
  grid-column: 2 / span 10;
}

.event-single--pre {
  position: absolute;
  top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  right: 0;
  width: 100%;
  border-bottom: solid 1px $body_color;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 0 $base * 2;
  gap: $base;
  text-transform: uppercase;
  font-size: $base * 2;
  font-family: $font_family_alt;
  pointer-events: none;

  @media (min-width: 1024px) {
    top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    gap: $base;
    padding-right: calc(
      ((100vw - ($base * 8) - (11 * $base * 3)) / 12) + $base * 5
    );
    padding-top: $base * 10;
    padding-bottom: $base;
  }
}

.events-single--header {
  grid-column: 2 / span 10;
  margin-bottom: $base * 5;

  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &--left {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: $base * 3;
      font-family: $font_family_alt;
      font-weight: 400;
      line-height: $base * 4;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        font-size: $base * 5.8;
        line-height: $base * 6;
      }
    }

    &__subtitle {
      font-size: $base * 2;
      font-family: $font_family_alt;
      font-weight: 400;
      line-height: $base * 3;
      text-transform: uppercase;
      color: rgba($body_color_rgb, 0.7);

      @media (min-width: 1024px) {
        font-size: $base * 2.625;
        line-height: $base * 3;
      }
    }

    &__date {
      font-size: $base * 2;
      font-family: $font_family_alt;
      font-weight: 400;
      line-height: $base * 3;
      text-transform: uppercase;
      color: rgba($body_color_rgb, 0.7);
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__external-link a {
      display: flex;
      white-space: nowrap;
      align-items: center;
      font-size: $base * 2.25;
      line-height: $base * 3;
      font-family: $font_family_alt;
      text-transform: uppercase;
      font-weight: 400;
      text-align: right;
      gap: $base * 0.5;

      & .icon {
        display: flex;
        align-items: center;
      }
    }

    &__location,
    &__date,
    &__time {
      font-size: $base * 2;
      color: rgba($body_color_rgb, 0.7);
    }
  }
}

.events-single--content {
  grid-column: 2 / span 10;
  display: flex;
  flex-direction: column-reverse;

  &__gallery {
    order: 3;
    grid-column: 1 / span 10;
    margin-top: $base * 2;
    margin-bottom: $base * 3;
  }

  &__left {
    order: 2;
    grid-column: 1 / span 5;
    margin-bottom: $base * 7.5;

    &.no-image-layout {
      grid-column: 1 / span 6;
    }
  }

  &__right {
    order: 3;
    grid-column: 7 / span 4;

    &__image {
      margin-bottom: $base * 5;

      &__img {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      &__caption {
        font-size: $base * 2.25;
        line-height: $base * 4;
        color: rgba($body_color_rgb, 0.7);
        margin-top: $base * 0.5;
      }
    }
  }

  &__bottom {
    order: 1;
    grid-column: 1 / span 10;
    margin-bottom: $base * 4;

    &__title {
      font-size: $base * 2.5;
      font-family: $font_family_alt;
      font-weight: 400;
      line-height: $base * 3;
      text-transform: uppercase;
      margin-bottom: $base * 1;
      text-align: center;
      width: 100%;
      padding-bottom: $base * 0.5;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    // grid-auto-rows: max-content;
    column-gap: $base * 3;

    &__gallery {
      order: 1;
      margin-top: $base * 5;
      margin-bottom: $base * 8;
    }

    &__bottom {
      order: 3;
      grid-column: 1 / span 10;

      &__title {
        text-align: left;
        padding: 0;
        font-size: $base * 4.5;
        line-height: $base * 5;
        margin-bottom: $base * 2;
      }
    }

    &__left {
      margin-bottom: $base * 12.5;
    }

    &__right {
      margin-bottom: $base * 12.5;
    }
  }
}
</style>
<style lang="scss">
.events-single--content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font_family_alt;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.2;
  }
}

.page-events-single {
  .back-button {
    margin-bottom: $base * 3 !important;

    @media (min-width: 1024px) {
      margin-bottom: $base * 8 !important;
    }
  }

  .breadcrumbs--inner {
    @media (max-width: 1024px) {
      margin-bottom: $base * 1;
    }
  }
}
</style>
