<template>
  <div class="lang-selector">
    <ul class="lang-selector--container">
      <li class="lang-selector--item">
        <a
          class="lang-selector--item__link"
          :class="[lang === 'es' ? 'lang-selector--item__link__active' : '']"
          @click="changeLang('es')"
        >
          Castellano
        </a>
      </li>
      <li class="lang-selector--item">
        <a
          class="lang-selector--item__link"
          :class="[lang === 'ca' ? 'lang-selector--item__link__active' : '']"
          @click="changeLang('ca')"
        >
          Valencià
        </a>
      </li>
      <li class="lang-selector--item">
        <a
          class="lang-selector--item__link"
          :class="[lang === 'en' ? 'lang-selector--item__link__active' : '']"
          @click="changeLang('en')"
        >
          English
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LangSelector",
  data() {
    return {
      lang: this.$root.$i18n.locale,
      languageOptions: [
        { value: "ca", text: "Valenciá" },
        { value: "es", text: "Castellano" },
        { value: "en", text: "English" },
      ],
    };
  },
  methods: {
    changeLang(lang) {
      this.lang = lang;
      this.$root.$i18n.locale = lang;
      // console.log(this.$root.$i18n.locale);
    },
    //add active class to clicked element and remove it from the rest
    //add arrow to active element and remove it from the rest
  },
};
</script>

<style lang="scss" scoped>
.lang-selector {
  margin-top: $base * 6;
  margin-bottom: $base * 4;
  ul.lang-selector--container {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 1024px) {
      align-items: flex-start;
    }
    .lang-selector--item {
      &__link {
        color: rgba($body_bg_rgb, 0.4);
        font-family: $font_family_base;
        font-size: $base * 2;
        line-height: $base * 3;
        position: relative;
        display: block;
        cursor: pointer;
        &__active {
          color: $corporate_color;
          @media (max-width: 1023px) {
            margin-right: -$base * 2.36;
            &:after {
              content: "←";
              font-family: $font_family_alt;
              color: $corporate_color;
              font-size: $base * 2.5;
              line-height: $base * 3;
            }
          }
          @media (min-width: 1024px) {
            margin-left: -$base * 2.36;
            &:before {
              content: "→";
              font-family: $font_family_alt;
              color: $corporate_color;
              font-size: $base * 2.5;
              line-height: $base * 3;
            }
          }
        }
      }
    }
  }
}
</style>
