<template>
  <div class="page-content page-people-single--content">
    <breadcrumbs-component
      :breadcrumbs="breadcrumbs"
      v-if="parsedPost"
    ></breadcrumbs-component>
    <div
      v-if="!this.$vuetify.display.mobile && parsedPost"
      class="people-single--left-col"
    >
      <header class="people-single--header">
        <h1 class="people-single--header__name" v-if="this.parsedPost.name">
          {{ parsedPost.name }}
        </h1>
        <div
          v-if="this.$vuetify.display.mobile && this.parsedPost.image.src"
          class="people-single--header__img"
        >
          <img
            :src="parsedPost.image.src"
            :alt="parsedPost.image.alt"
            v-if="parsedPost.image.src"
          />
        </div>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'es'"
        >
          {{ parsedPost.job.es }}
        </h2>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'ca'"
        >
          {{ parsedPost.job.cat ? parsedPost.job.cat : parsedPost.job.es }}
        </h2>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'en'"
        >
          {{ parsedPost.job.en ? parsedPost.job.en : parsedPost.job.es }}
        </h2>
        <h3
          class="people-single--header__unit"
          v-if="this.parsedPost.unit.name !== ''"
        >
          {{ $t("faculty_single") }}
          <router-link
            :to="
              '/units/' + parsedPost.unit.id + '/' + parsedPost.unit.name + '/'
            "
          >
            {{ parsedPost.unit.name }}
          </router-link>
        </h3>
        <h4
          class="people-single--header__department"
          v-if="this.parsedPost.departments.length > 0"
        >
          <div
            class="people-single--header__department__item"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="
                department.name_cat ? department.name_cat : department.name_es
              "
            >
              {{
                department.name_cat ? department.name_cat : department.name_es
              }}
            </div>
          </div>
          <div
            class="people-single--header__department__item"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="department.name_es"
            >
              {{ department.name_es }}
            </div>
          </div>
          <div
            class="people-single--header__department__item"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="
                department.name_en ? department.name_en : department.name_es
              "
            >
              {{ department.name_en ? department.name_en : department.name_es }}
            </div>
          </div>
        </h4>
      </header>
      <section class="people-single--description" v-if="!this.isLoading">
        <div v-if="this.$root.$i18n.locale === 'es'">
          {{ parsedPost.description.es }}
        </div>
        <div v-if="this.$root.$i18n.locale === 'ca'">
          {{
            parsedPost.description.cat
              ? parsedPost.description.cat
              : parsedPost.description.es
          }}
        </div>
        <div v-if="this.$root.$i18n.locale === 'en'">
          {{
            parsedPost.description.en
              ? parsedPost.description.en
              : parsedPost.description.es
          }}
        </div>
      </section>
    </div>

    <div
      v-if="!this.$vuetify.display.mobile && parsedPost"
      class="people-single--right-col"
    >
      <aside class="people-single--aside">
        <div class="people-single--aside__img" v-if="!this.isLoading">
          <img :src="parsedPost.image.src" :alt="parsedPost.image.alt" />
        </div>
        <div class="people-single--aside--info">
          <div
            class="people-single--aside--info--field research-areas"
            v-if="parsedPost.researchAreas.length > 0"
          >
            <h5 class="people-single--aside--info--field__title">
              {{ $t("research_areas") }}
            </h5>
            <div
              class="people-single--aside--info--field__content"
              v-if="!this.isLoading"
            >
              <template
                v-if="
                  this.$root.$i18n.locale === 'es' && parsedPost.researchAreas
                "
              >
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_es"
                >
                  {{ area.name_es }}
                </div>
              </template>
              <template
                v-if="
                  this.$root.$i18n.locale === 'ca' && parsedPost.researchAreas
                "
              >
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_cat ? area.name_cat : area.name_es"
                >
                  {{ area.name_cat ? area.name_cat : area.name_es }}
                </div>
              </template>
              <template
                v-if="
                  this.$root.$i18n.locale === 'en' && parsedPost.researchAreas
                "
              >
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_en ? area.name_en : area.name_es"
                >
                  {{ area.name_en ? area.name_en : area.name_es }}
                </div>
              </template>
            </div>
          </div>
          <div
            class="people-single--aside--info--field contact-info"
            v-if="
              !this.isLoading &&
              (parsedPost.contact.email != '' ||
                parsedPost.contact.instagram != '')
            "
          >
            <h5
              class="people-single--aside--info--field__title contact-info__title"
            >
              {{ $t("contact") }}
            </h5>
            <div
              class="people-single--aside--info--field__content contact-info__content"
            >
              <a
                :href="'mailto:' + parsedPost.contact.email"
                class="contact-info__content__mail"
                v-if="parsedPost.contact.email != ''"
              >
                <span class="contact-info__content__mail__icon">
                  <v-icon color="#FF9900">mdi-email-outline</v-icon>
                </span>
                <span class="contact-info__content__mail__text">{{
                  parsedPost.contact.email
                }}</span>
              </a>
              <a
                :href="parsedPost.contact.instagram"
                target="_blank"
                class="contact-info__content__instagram"
                v-if="parsedPost.contact.instagram != ''"
              >
                <span class="contact-info__content__instagram__icon">
                  <v-icon color="#FF9900">mdi-instagram</v-icon>
                </span>
                <span class="contact-info__content__instagram__text">
                  Instagram
                </span>
              </a>
            </div>
          </div>
          <div
            class="people-single--aside--info--field other-links"
            v-if="!this.isLoading && parsedPost.otherLinks.length > 0"
          >
            <h5 class="people-single--aside--info--field__title">
              {{ $t("other_links") }}
            </h5>
            <div class="people-single--aside--info--field__content">
              <template v-if="this.$root.$i18n.locale === 'es'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{ link.link_text.text_es }}
                  </div>
                </a>
              </template>
              <template v-if="this.$root.$i18n.locale === 'ca'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{
                      link.link_text.text_cat
                        ? link.link_text.text_cat
                        : link.link_text.text_es
                    }}
                  </div>
                </a>
              </template>
              <template v-if="this.$root.$i18n.locale === 'en'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{
                      link.link_text.text_en
                        ? link.link_text.text_en
                        : link.link_text.text_es
                    }}
                  </div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <section
      class="people-single--publications"
      v-if="
        !this.$vuetify.display.mobile &&
        this.parsedPost &&
        this.parsedPost.publications.length > 0
      "
    >
      <div class="people-single--publications__header">
        <h3 class="people-single--publications__header__title">
          {{ $t("publications") }}
        </h3>
      </div>
      <div class="people-single--publications__list" v-if="!this.isLoading">
        <div
          class="people-single--publications__list__item"
          v-for="publication in parsedPost.publications"
          :key="publication.img"
        >
          <div class="people-single--publications__list__item__img">
            <img :src="publication.image.sizes.medium_large" alt="" />
          </div>
          <div class="people-single--publications__list__item__content">
            <div class="people-single--publications__list__item__content__info">
              <h4
                class="people-single--publications__list__item__content__info__title"
              >
                <span v-if="this.$root.$i18n.locale === 'es'">{{
                  publication.title.title_es
                }}</span>
                <span v-if="this.$root.$i18n.locale === 'ca'">{{
                  publication.title.title_cat
                    ? publication.title.title_cat
                    : publication.title.title_es
                }}</span>
                <span v-if="this.$root.$i18n.locale === 'en'">{{
                  publication.title.title_en
                    ? publication.title.title_en
                    : publication.title.title_es
                }}</span>
              </h4>
              <div
                class="people-single--publications__list__item__content__info__description"
              >
                <span v-if="this.$root.$i18n.locale === 'es'">{{
                  publication.description.description_es
                }}</span>
                <span v-if="this.$root.$i18n.locale === 'ca'">{{
                  publication.description.description_cat
                    ? publication.description.description_cat
                    : publication.description.description_es
                }}</span>
                <span v-if="this.$root.$i18n.locale === 'en'">{{
                  publication.description.description_en
                    ? publication.description.description_en
                    : publication.description.description_es
                }}</span>
              </div>
            </div>
            <div class="people-single--publications__list__item__content__btn">
              <a :href="publication.link" target="_blank"
                ><svg
                  width="49"
                  height="92"
                  viewBox="0 0 49 92"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 3L46 46L3 89"
                    stroke="#5A5A5A"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="this.$vuetify.display.mobile && parsedPost">
      <header class="people-single--header">
        <h1 class="people-single--header__name" v-if="this.parsedPost.name">
          {{ parsedPost.name }}
        </h1>
        <div
          v-if="this.$vuetify.display.mobile && !this.isLoading"
          class="people-single--header__img"
        >
          <img :src="parsedPost.image.src" :alt="parsedPost.image.alt" />
        </div>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'es'"
        >
          {{ parsedPost.job.es }}
        </h2>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'ca'"
        >
          {{ parsedPost.job.cat ? parsedPost.job.cat : parsedPost.job.es }}
        </h2>
        <h2
          class="people-single--header__job"
          v-if="this.parsedPost.job && this.$root.$i18n.locale === 'en'"
        >
          {{ parsedPost.job.en ? parsedPost.job.en : parsedPost.job.es }}
        </h2>
        <h3
          class="people-single--header__unit"
          v-if="this.parsedPost.unit.name !== ''"
        >
          {{ $t("faculty_single") }}
          <router-link
            :to="
              '/units/' + parsedPost.unit.id + '/' + parsedPost.unit.name + '/'
            "
          >
            {{ parsedPost.unit.name }}
          </router-link>
        </h3>
        <h4
          class="people-single--header__department"
          v-if="this.parsedPost.departments.length > 0"
        >
          <div
            class="people-single--header__department__item department-cat"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="
                department.name_cat ? department.name_cat : department.name_es
              "
            >
              {{
                department.name_cat ? department.name_cat : department.name_es
              }}
            </div>
          </div>
          <div
            class="people-single--header__department__item department-es"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="department.name_es"
            >
              {{ department.name_es }}
            </div>
          </div>
          <div
            class="people-single--header__department__item department-en"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            <div
              v-for="department in this.parsedPost.departments"
              :key="
                department.name_en ? department.name_en : department.name_es
              "
            >
              {{ department.name_en ? department.name_en : department.name_es }}
            </div>
          </div>
        </h4>
      </header>
      <aside class="people-single--aside">
        <div class="people-single--aside--info">
          <div
            class="people-single--aside--info--field research-areas"
            v-if="parsedPost.researchAreas.length > 0"
          >
            <h5 class="people-single--aside--info--field__title">
              {{ $t("research_areas") }}
            </h5>
            <div
              class="people-single--aside--info--field__content"
              v-if="!this.isLoading"
            >
              <template v-if="this.$root.$i18n.locale === 'es'">
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_es"
                >
                  {{ area.name_es }}
                </div>
              </template>
              <template v-if="this.$root.$i18n.locale === 'ca'">
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_cat ? area.name_cat : area.name_es"
                >
                  {{ area.name_cat ? area.name_cat : area.name_es }}
                </div>
              </template>
              <template v-if="this.$root.$i18n.locale === 'en'">
                <div
                  v-for="area in parsedPost.researchAreas"
                  :key="area.name_en ? area.name_en : area.name_es"
                >
                  {{ area.name_en ? area.name_en : area.name_es }}
                </div>
              </template>
            </div>
          </div>
          <div
            class="people-single--aside--info--field contact-info"
            v-if="
              !this.isLoading &&
              (parsedPost.contact.email != '' ||
                parsedPost.contact.instagram != '')
            "
          >
            <h5 class="people-single--aside--info--field__title">Contact</h5>
            <div class="people-single--aside--info--field__content">
              <a
                :href="'mailto:' + parsedPost.contact.email"
                class="contact-info__content__mail"
                v-if="parsedPost.contact.email"
              >
                <span class="contact-info__content__mail__icon">
                  <v-icon color="#FF9900">mdi-email-outline</v-icon>
                </span>
                <span class="contact-info__content__mail__text">{{
                  parsedPost.contact.email
                }}</span>
              </a>
              <a
                :href="parsedPost.contact.instagram"
                target="_blank"
                class="contact-info__content__instagram"
                v-if="parsedPost.contact.instagram"
              >
                <span class="contact-info__content__instagram__icon">
                  <v-icon color="#FF9900">mdi-instagram</v-icon>
                </span>
                <span class="contact-info__content__instagram__text">
                  Instagram
                </span>
              </a>
            </div>
          </div>
          <div
            class="people-single--aside--info--field other-links"
            v-if="!this.isLoading && parsedPost.otherLinks.length > 0"
          >
            <h5 class="people-single--aside--info--field__title">
              {{ $t("otherLinks") }}
            </h5>
            <div class="people-single--aside--info--field__content">
              <template v-if="this.$root.$i18n.locale === 'es'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{ link.link_text.text_es }}
                  </div>
                </a>
              </template>
              <template v-if="this.$root.$i18n.locale === 'ca'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{
                      link.link_text.text_cat
                        ? link.link_text.text_cat
                        : link.link_text.text_es
                    }}
                  </div>
                </a>
              </template>
              <template v-if="this.$root.$i18n.locale === 'en'">
                <a
                  href=""
                  target="_blank"
                  class="other-links--item"
                  v-for="link in parsedPost.otherLinks"
                  :key="link.url"
                >
                  <span class="other-links--item__icon">
                    <v-icon color="#FF9900">mdi-open-in-new</v-icon>
                  </span>
                  <div class="other-links--item__text">
                    {{
                      link.link_text.text_en
                        ? link.link_text.text_en
                        : link.link_text.text_es
                    }}
                  </div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </aside>
      <section class="people-single--description" v-if="!this.isLoading">
        <div v-if="this.$root.$i18n.locale === 'es'">
          {{ parsedPost.description.es }}
        </div>
        <div v-if="this.$root.$i18n.locale === 'ca'">
          {{
            parsedPost.description.cat
              ? parsedPost.description.cat
              : parsedPost.description.es
          }}
        </div>
        <div v-if="this.$root.$i18n.locale === 'en'">
          {{
            parsedPost.description.en
              ? parsedPost.description.en
              : parsedPost.description.es
          }}
        </div>
      </section>
      <section
        class="people-single--publications"
        v-if="!this.isLoading && parsedPost.publications.length > 0"
      >
        <div class="people-single--publications__header">
          <h3 class="people-single--publications__header__title">
            {{ $t("publications") }}
          </h3>
          <div class="people-single--publications__list">
            <div
              class="people-single--publications__list__item"
              v-for="publication in parsedPost.publications"
              :key="publication.link"
            >
              <div class="people-single--publications__list__item__header">
                <h4
                  class="people-single--publications__list__item__header__title"
                >
                  <span v-if="this.$root.$i18n.locale === 'es'">{{
                    publication.title.title_es
                  }}</span>
                  <span v-if="this.$root.$i18n.locale === 'ca'">{{
                    publication.title.title_cat
                      ? publication.title.title_cat
                      : publication.title.title_es
                  }}</span>
                  <span v-if="this.$root.$i18n.locale === 'en'">{{
                    publication.title.title_en
                      ? publication.title.title_en
                      : publication.title.title_es
                  }}</span>
                </h4>
                <div
                  class="people-single--publications__list__item__header__btn"
                >
                  <a :href="publication.link" target="_blank">
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.55994 14H10.4062L17 6.98862L10.4062 0H8.55994L14.3865 6.32846H0V7.67154H14.3625L8.55994 14Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div class="people-single--publications__list__item__content">
                <div
                  class="people-single--publications__list__item__content__img"
                  v-if="!this.loading"
                >
                  <img :src="publication.image.sizes.medium_large" alt="" />
                </div>
                <div
                  class="people-single--publications__list__item__content__description"
                >
                  <div v-if="this.$root.$i18n.locale === 'es' && !this.loading">
                    {{ publication.description.description_es }}
                  </div>
                  <div v-if="this.$root.$i18n.locale === 'ca' && !this.loading">
                    {{
                      publication.description.description_cat
                        ? publication.description.description_cat
                        : publication.description.description_es
                    }}
                  </div>
                  <div v-if="this.$root.$i18n.locale === 'en' && !this.loading">
                    {{
                      publication.description.description_en
                        ? publication.description.description_en
                        : publication.description.description_es
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import PostsService from "@/services/posts.service.js";
import ImagesService from "@/services/images.service.js";
import BreadcrumbsComponent from "@/components/layout/BreadcrumbsComponent.vue";
export default {
  name: "SingleNewsView",
  components: {
    BreadcrumbsComponent,
  },
  data() {
    return {
      post: null,
      parsedPost: null,
      isLoading: true,
    };
  },
  async created() {
    await this.fetchPost();
    if (this.parsedPost) {
      document.title = "DPA UPV | " + this.parsedPost.name;
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: {
            es: this.$t("people"),
            ca: this.$t("people"),
            en: this.$t("people"),
          },
          to: "/people",
        },
        {
          text: {
            es: this.$t("faculty"),
            ca: this.$t("faculty"),
            en: this.$t("faculty"),
          },
          to: "/people?taxonomyId=25",
        },
        {
          text: {
            es: this.parsedPost ? this.parsedPost.name : "",
            ca: this.parsedPost ? this.parsedPost.name : "",
            en: this.parsedPost ? this.parsedPost.name : "",
          },
          to: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    getImageUrl(imagen, size) {
      return ImagesService.getImageUrl(imagen, size);
    },

    async fetchPost() {
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API

      try {
        const res = await PostsService.getSinglePeople(this.$route.params.id);

        this.parsedPost = {
          name: res.title.rendered,
          job: {
            es: res.acf.faculty_info.job.name_es
              ? res.acf.faculty_info.job.name_es
              : "",
            cat: res.acf.faculty_info.job.name_cat
              ? res.acf.faculty_info.job.name_cat
              : "",
            en: res.acf.faculty_info.job.name_en
              ? res.acf.faculty_info.job.name_en
              : "",
          },
          image: {
            src: res.acf.image
              ? this.getImageUrl(res.acf.image, "large")
              : undefined,
            alt: res.title.rendered,
          },
          unit: {
            name:
              res.acf.faculty_info.unit && res.acf.faculty_info.unit.post_name
                ? res.acf.faculty_info.unit.post_name
                : "",
            id:
              res.acf.faculty_info.unit && res.acf.faculty_info.unit.ID
                ? res.acf.faculty_info.unit.ID
                : "",
          },
          departments: res.acf.faculty_info.departments
            ? res.acf.faculty_info.departments
            : [],
          description: {
            cat: res.acf.faculty_info.description.description_cat
              ? res.acf.faculty_info.description.description_cat
              : "",
            es: res.acf.faculty_info.description.description_es
              ? res.acf.faculty_info.description.description_es
              : "",
            en: res.acf.faculty_info.description.description_en
              ? res.acf.faculty_info.description.description_en
              : "",
          },
          contact: {
            email: res.acf.faculty_info.contact.email
              ? res.acf.faculty_info.contact.email
              : "",
            instagram: res.acf.faculty_info.contact.instagram
              ? res.acf.faculty_info.contact.instagram
              : "",
          },
          researchAreas: res.acf.faculty_info.research_areas
            ? res.acf.faculty_info.research_areas
            : [],
          otherLinks: res.acf.faculty_info.contact.other_links
            ? res.acf.faculty_info.contact.other_links
            : [],
          publications: res.acf.faculty_info.publications
            ? res.acf.faculty_info.publications
            : [],
        };
      } catch (error) {
        console.error("Error al llamar a la API:", error);
      } finally {
        this.isLoading = false;
        this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-people-single--content {
  padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;
  margin-bottom: $base * 4;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    column-gap: $base * 3;
  }

  .breadcrumbs-nav {
    grid-column: 2 / span 11;
  }

  .people-single--left-col {
    grid-column: 2 / span 6;
  }

  .people-single--right-col {
    grid-column: 9 / span 3;
  }

  .people-single--header {
    display: flex;
    flex-direction: column;

    &__name {
      font-size: $base * 4;
      line-height: $base * 4.5;
      font-weight: 400;
      font-family: $font_family_alt;
      text-transform: uppercase;
      margin-bottom: $base * 0.625;
      max-width: 80%;

      @media (min-width: 1024px) {
        font-size: $base * 8.5;
        line-height: $base * 9;
        margin-bottom: $base * 1.375;
        max-width: 100%;
      }
    }

    &__job {
      font-size: $base * 2;
      line-height: 1;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: $base * 1.5;

      @media (min-width: 1024px) {
        font-size: $base * 3;
        line-height: $base * 3;
        margin-bottom: $base * 1;
      }
    }

    &__unit {
      font-size: $base * 2;
      line-height: 1;
      font-weight: 400;
      color: rgba($body_color_rgb, 0.7);
      margin-bottom: $base * 1.5;

      a {
        color: $corporate_color;
        text-transform: uppercase;
      }

      @media (min-width: 1024px) {
        font-size: $base * 2.625;
        line-height: $base * 3;
        margin-bottom: $base * 1;
      }
    }

    &__department {
      font-size: $base * 2;
      font-weight: 400;
      line-height: $base * 3;
      color: rgba($body_color_rgb, 0.7);
      margin-bottom: $base * 3.375;

      div {
        font-size: $base * 2;
        font-weight: 400;
        margin-bottom: 0;
        padding: 0;
      }

      @media (min-width: 1024px) {
        div {
          font-size: $base * 3;
          line-height: $base * 4;
          margin-bottom: 0;
        }

        margin-bottom: $base * 1;
      }
    }

    //movil image
    &__img {
      width: 100%;
      height: 100%;
      max-width: 55.46666667vw;
      aspect-ratio: 1/1;
      margin-bottom: $base * 1.5;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .people-single--description {
    grid-column: 2 / span 6;

    p {
      font-size: $base * 1.75;
      line-height: $base * 2.75;

      @media (min-width: 1024px) {
        font-size: $base * 2.625;
        line-height: $base * 4;
      }
    }
  }

  .people-single--publications {
    grid-column: 2 / span 10;
    margin-top: $base * 12;

    @media (min-width: 1024px) {
      margin-top: $base * 7.625;
    }

    &__header {
      &__title {
        font-size: $base * 2.25;
        line-height: $base * 3;
        font-family: $font_family_alt;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: $base;
        margin-bottom: $base * 8;

        @media (min-width: 1024px) {
          font-size: $base * 3.5;
          line-height: $base * 4;
          margin-bottom: $base * 7.625;
          gap: $base * 2;
        }

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: rgba($body_color_rgb, 1);
          margin-bottom: $base;
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: rgba($body_color_rgb, 1);
          margin-bottom: $base;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: $base * 4;

      @media (min-width: 1024px) {
        gap: $base * 3;
      }

      &__item {
        flex-direction: column;
        flex-wrap: wrap;
        border-left: none;
        width: 100%;
        gap: $base * 2;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -$base * 2;
          width: 1px;
          height: 100%;
          background-color: rgba($body_color_rgb, 1);
        }

        @media (min-width: 1024px) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 0;

          &::before {
            display: none;
          }
        }

        &__header {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &__title {
            font-size: $base * 2;
            line-height: $base * 3;
            font-weight: 400;
            text-transform: uppercase;
            font-family: $font_family_alt;
          }
        }

        &__img {
          max-width: 13.75661376vw;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: $base * 2;
          width: 100%;

          @media (min-width: 1024px) {
            border: solid 1px $body_color;
            border-left: none;
            padding: $base * 2;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            gap: $base * 2;
          }

          &__img {
            width: 100%;
            height: auto;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          &__description {
            font-size: $base * 1.75;
            line-height: $base * 3;
            width: 100%;
          }

          &__info {
            display: flex;
            flex-direction: column;
            gap: $base * 0.5;
            width: 100%;

            &__title {
              font-size: $base * 3.5;
              line-height: $base * 4;
              font-family: $font_family_alt;
              text-transform: uppercase;
              font-weight: 400;
            }

            &__description {
              font-size: $base * 2.625;
              line-height: $base * 4;
              margin-top: auto;
            }
          }
        }
      }
    }
  }

  .people-single--aside {
    &__img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      margin-bottom: $base * 5.25;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .people-single--aside--info {
      margin-bottom: $base * 6.25;

      @media (min-width: 1024px) {
        margin-bottom: $base * 4;
      }

      .people-single--aside--info--field {
        margin-bottom: $base * 4.125;

        &__title {
          font-size: $base * 2.25;
          line-height: $base * 4;
          text-transform: uppercase;
          font-family: $font_family_alt;
          font-weight: 400;
          margin-bottom: $base;

          @media (min-width: 1024px) {
            font-size: $base * 2.625;
            line-height: $base * 4;
          }
        }

        &__content {
          font-size: $base * 2;
          line-height: $base * 4;
          color: rgba($body_color_rgb, 0.7);
          display: flex;
          flex-direction: column;
          gap: $base * 1;

          @media (min-width: 1024px) {
            gap: $base * 0;
          }

          a {
            display: flex;
            gap: $base;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0;
          }
        }
      }
    }

    .contact-info {
      a {
        color: $corporate_color;
      }
    }

    .other-links {
      a {
        color: $corporate_color;
        display: flex;
      }
    }
  }
}
</style>
